.timer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .timer-circle-bg {
    stroke: #eee; /* Background circle color */
    stroke-width: 4; /* Adjust the thickness of the background circle */
  }
  
  .timer-circle {
    transition: stroke-dashoffset 1s linear; /* Smooth transition for the depletion animation */
  }
  
  text {
    font-size: 2em; /* Adjust text size as needed */
    fill: #333; /* Text color */
  }